<template>
  <div class="vx-row px-5" v-show="!loading">
    <!-- Brand Description -->
    <div class="vx-col w-full mb-base">
      <h1 class="text-xl font-medium mb-4">
        Property Description
      </h1>

      <div class="vx-card p-5 w-full">
        <p v-html="brand_description"></p>
      </div>
    </div>

    <!-- Product Property Description -->
    <div class="vx-col w-full mb-base">
      <h1 class="text-xl font-medium mb-4">
        Room Description
      </h1>

      <div
        class="vx-card p-5 mb-4 min-w-full"
        v-for="(property, i) in properties"
        :key="i"
      >
        <span class="font-bold">{{ property.name }}</span>
        <p v-html="property.description"></p>
      </div>
    </div>

    <!-- Fine Prints -->
    <div class="vx-col w-full">
      <h1 class="text-xl font-medium mb-4">
        Fine Prints
      </h1>

      <div class="vx-card p-5">
        <p class="mb-4">
          Let us know any important details guests should be aware of before
          they book :
        </p>

        <div class="vx-row">
          <div
            class="vx-col w-full"
            v-for="(fine_print, i) in fine_prints"
            :key="i"
          >
            <vs-checkbox
              :class="[i == fine_prints.length - 1 ? '' : 'mb-3']"
              :vs-value="fine_print.id"
              v-model="payload.fine_prints"
            >
              {{ fine_print.name }}
            </vs-checkbox>

            <vs-divider v-show="i !== fine_prints.length - 1"></vs-divider>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-col w-full mt-base" v-if="$store.state.AppActiveUser.userInfo.roles[0].permissions.map(permission => permission.name).includes('update_view_description')">
      <vs-button @click="storeData">Update Property Info</vs-button>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "VancyNetViewDescription",

  metaInfo: {
    title: "View Description",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data() {
    return {
      properties: [],
      fine_prints: [],

      payload: {
        fine_prints: []
      },

      brand_description: "",
      loading: true
    };
  },

  methods: {
    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      axios
        .get(`vancynet/description`)
        .then(({ data: res }) => {
          this.brand_description = res.data.brand.description;
          this.properties = res.data.properties;
          this.fine_prints = res.data.fine_prints;
          this.payload.fine_prints = res.data.brand.fine_prints.map(
            fine_print => fine_print.id
          );
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      axios
        .post(`vancynet/description`, this.payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Success",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });
        })
        .catch(err => this.$catchErrorResponse(err));
    }
  },

  created() {
    this.getData();
  }
};
</script>

<style lang="scss">
.con-vs-radio,
.con-vs-checkbox {
  display: flex !important;
  align-items: flex-start !important;
}

.con-vs-checkbox span {
  min-width: 18px !important;
  min-height: 18px !important;
}
</style>
